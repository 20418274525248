export const UpsellPages = [
    {
        path: 'UpsellPages/UpsellCourseFacelifting',
        route: 'upsell-course-facelifting',
    },
    {
        path: 'UpsellPages/Upsale2A',
        route: 'upsale-2-a',
    },
    {
        path: 'UpsellPages/Upsell2B',
        route: 'upsell-2-b',
    },
    {
        path: 'UpsellPages/UpsellVideoCourseSub',
        route: 'upsell-sub-course',
    },
    {
        path: 'UpsellPages/UpsellVideoCourseSubTech',
        route: 'upsell-sub-course-tech',
    },
    {
        path: 'UpsellPages/UpsellSub',
        route: 'upsell-sub',
    },
    {
        path: 'UpsellPages/UpsellCoach',
        route: 'upsell-coach',
    },
];
