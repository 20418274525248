import { setShortFlow, setSkipUpsellCoach } from 'redux/Payment/actions';

import { UPSELL_SUB, UPSELL_COACH } from '../constants/payments';

import { getLanguage } from 'helpers/utils';

import {
    getConfigValidator,
    getObConfigValidator,
    getObUnfortunatelyValidator,
    getUpsellSubscriptionValidator,
} from '../validators/flowValidators';

export const validateFlow = ({ config, dispatch, country, currentFlowName, setCurrentFlowName }) => {
    let status = true;

    const language = getLanguage();

    const { payment_flow, onboarding_flow } = config;

    // TODO: Bad naming, rename 'check-' to 'find-'
    const checkFlow = (flowId) => payment_flow.find((item) => item.id === flowId);
    const checkObFlow = (flowId) => onboarding_flow.find((item) => item.id === flowId);

    const runner = (rules) => {
        for (const rule of rules) {
            const errorMessage = '%c Flow validation error - ' + rule.message;

            if (!rule.exec(rule.args)) {
                status = false;

                // for case if error on flow, and default flow include target page
                dispatch(setShortFlow(false));

                /* eslint-disable no-console */
                console.log(errorMessage, 'color:red;');

                break;
            }
        }
    };

    const runConfig = () => {
        const validator = getConfigValidator(config);

        runner(validator);
    };

    const runFlow = (flow, flowName, flowValidator, withConfig) => {
        setCurrentFlowName(flowName);

        const { products, config } = flow;

        const validator = flowValidator(products, withConfig && config);

        if (status) runner(validator);
    };

    const runObFlow = (flow, flowName, flowValidator) => {
        setCurrentFlowName(flowName);

        const validator = flowValidator(flow);

        if (status) runner(validator);
    };

    runConfig();

    // Flow with upsell
    const withUpsellSub = checkFlow(UPSELL_SUB);
    const withUpsellCoach = checkFlow(UPSELL_COACH);

    // Onboarding Flow
    const obConfigFlows = ['ob_config_1', 'ob_config_2', 'ob_config_3'].map(checkObFlow).filter(Boolean);
    const obUnfortunatelyFlow = checkObFlow('ob_unfortunately');

    obConfigFlows.forEach((obConfigFlow) => {
        runObFlow(obConfigFlow, `Onboarding Config Screen ${obConfigFlow.id}`, getObConfigValidator);
    });

    if (obUnfortunatelyFlow) {
        runObFlow(obUnfortunatelyFlow, 'Onboarding Unfortunately', getObUnfortunatelyValidator);
    }

    if (withUpsellSub) {
        runFlow(withUpsellSub, 'Upsell Sub', getUpsellSubscriptionValidator, true);
    }

    if (withUpsellCoach && (language !== 'en' || country !== 'US')) {
        dispatch(setSkipUpsellCoach(true));
    }

    return Promise.resolve({ name: `validate ${currentFlowName} flow`, status });
};
