import { PRODUCT_CODES } from 'constants/payments';

export const checkProductsCode = ({ products }) => {
    return products.every((item) => PRODUCT_CODES.includes(item.name));
};

export const checkProductsConfig = (config) => {
    const keys = ['backgroundImg', 'courseTitle', 'courseDescription', 'disclaimerLogic'];
    const titles = [
        'own_dietician',
        'eat_mindfully',
        'eat_less',
        'intermittent_fasting',
        'healthy_living',
        'transform_health',
        'mindful_planning',
        'wellness_coach',
        'twellness_coach',
    ];
    const descriptions = [
        'best_foods',
        'calorie_sources',
        'own_plans',
        'professionals',
        'lifelong_results',
        'quality',
        'first_step',
        'calorie_intake',
        'mindful_eater',
        'right_number',
        'fasting_hours',
        'guesswork',
        'step_forward',
        'own_pace',
        'step_by_step',
        'maximize_results',
        'stay_on_track',
        'fasting_type',
    ];
    const disclaimers = ['regular', 'intro'];

    const hasAllKeys = keys.every((item) => {
        return config[item];
    });

    if (hasAllKeys) {
        const hasCorrectTitle = titles.includes(config.courseTitle);

        if (!hasCorrectTitle) return false;

        const hasCorrectDescription = config.courseDescription.split(',').every((item) => {
            return descriptions.includes(item.replace(/'/g, ''));
        });

        if (!hasCorrectDescription) return false;

        return disclaimers.includes(config.disclaimerLogic);
    }

    return false;
};
