import { checkProductsCode, checkProductsConfig } from 'helpers/paymentFlow/utils';
import { checkOptionsCount, checkKeysCount } from 'helpers/onboardingFlow/utils';

import { checkConfig, checkPagesId, checkAnalyticsData } from './configValidator';

export const getConfigValidator = (config) => {
    return [
        { exec: checkPagesId, args: config, message: 'INVALID PAGE ID' },
        { exec: checkConfig, args: config, message: 'INVALID CONFIG' },
        { exec: checkAnalyticsData, args: config, message: 'INVALID ANALYTICS DATA IN CONFIG' },
    ];
};

export const getUpsellSubscriptionValidator = (products, config) => {
    return [
        {
            exec: checkProductsCode,
            args: { products },
            message: 'PRODUCTS CODE',
        },
        {
            exec: checkProductsConfig,
            args: config,
            message: 'ERRORS IN CONFIG',
        },
    ];
};

export const getObConfigValidator = (flow) => {
    const config = flow?.config;

    return [
        {
            exec: checkOptionsCount,
            args: { config },
            message: 'OPTIONS COUNT',
        },
    ];
};

export const getObUnfortunatelyValidator = (flow) => {
    const config = flow?.config;

    return [
        {
            exec: checkKeysCount,
            args: { config },
            message: 'KEYS COUNT',
        },
    ];
};
