/* eslint-disable max-lines */
export const DEFAULT_FLOW_PROJECT_NAME = 'mycoach';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW = {
    branch_name: 'default_client',
    flow_name: 'Default_Client_coach',
    start_page: { id: 'index', name: 'index', config: {} },
    onboarding_flow: [
        { id: 'ob_social_proof', name: 'ob_social_proof', config: {} },
        { id: 'dem_motive_goal', name: 'dem_motive_goal', config: {} },
        { id: 'active_flev_act_body', name: 'active_flev_act_body', config: {} },
        { id: 'active_flev_targ_body', name: 'active_flev_targ_body', config: {} },
        { id: 'active_flev_targ_z', name: 'active_flev_targ_z', config: {} },
        { id: 'behave_hist_perfectW8', name: 'behave_hist_perfectW8', config: {} },
        { id: 'ob_fitness_level', name: 'ob_fitness_level', config: {} },
        { id: 'ob_stairs_over', name: 'ob_stairs_over', config: {} },
        { id: 'ob_fitness_walking', name: 'ob_fitness_walking', config: {} },
        { id: 'ob_pushups_squats', name: 'ob_pushups_squats', config: {} },
        { id: 'ob_height_a', name: 'ob_height_a', config: {} },
        { id: 'ob_weight_current_a', name: 'ob_weight_current_a', config: {} },
        { id: 'ob_weight_target_a', name: 'ob_weight_target_a', config: {} },
        { id: 'ob_age_a', name: 'ob_age_a', config: {} },
        { id: 'ob_feedback_age', name: 'ob_feedback_age', config: {} },
        { id: 'nutr_habbit_feel', name: 'nutr_habbit_feel', config: {} },
        { id: 'active_habit_slip', name: 'active_habit_slip', config: {} },
        { id: 'nutr_habbit_water', name: 'nutr_habbit_water', config: {} },
        { id: 'diets', name: 'diets', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'behave_eating_sc', name: 'behave_eating_sc', config: {} },
        { id: 'behave_multitasking_sc', name: 'behave_multitasking_sc', config: {} },
        { id: 'behave_routines_sc', name: 'behave_routines_sc', config: {} },
        { id: 'behave_oldhabits_sc', name: 'behave_oldhabits_sc', config: {} },
        { id: 'behave_impulse_sc', name: 'behave_impulse_sc', config: {} },
        { id: 'feedback_summary_fitlevel', name: 'feedback_summary_fitlevel', config: {} },
        { id: 'creating_b', name: 'creating_b', config: {} },
        { id: 'email_a', name: 'email_a', config: {} },
        { id: 'email_consent', name: 'email_consent', config: {} },
        { id: 'ob_name', name: 'ob_name', config: {} },
        { id: 'result_a', name: 'result_a', config: {} },
    ],
    'payment_flow': [
        {
            id: 'offer_a4_foxtrot16',
            name: 'offer_a4_foxtrot16_p1_sub_fullprice',
            parent_id: 'payment_g4_foxtrot16',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'ab90f9ca-d365-4468-887a-51b01201e78e',
                    name: 'One-week intro (5.50) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 150,
                    price_per_state: 79,
                    price: 550,
                    start_price: 1049,
                    ltv: 3043
                },
                {
                    payment_type: 'subscription',
                    id: '3af9e081-b45d-401e-b858-c6a68e153467',
                    name: 'One-month intro (11.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 100,
                    price_per_state: 39,
                    price: 1199,
                    start_price: 3099,
                    ltv: 3724
                },
                {
                    payment_type: 'subscription',
                    id: 'eb667562-a647-4ee7-bd72-b591148dd0d9',
                    name: 'Three-month intro (21.49) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 59,
                    price_per_state: 24,
                    price: 2149,
                    start_price: 5319,
                    ltv: 4662
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 150,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3409
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 100,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 5005
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 59,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 6638
                }
            ],
            config: {
                productSubtitle: 'on',
                '2ndBadge': 'most_popular',
                '3dBadge': 'best_value',
                saveBadge: 'off',
                fullPrice: 'on',
            }
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2_b',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 30,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 209
                }
            ],
            config: {}
        },
        {
            id: 'upsell_2_b',
            name: 'upsell_2_b',
            parent_id: 'upsell_sub_course_tech',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: true,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 513
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1500,
                    price: 1500,
                    start_price: 1500,
                    ltv: 1101
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                }
            ],
            config: {}
        },
        {
            id: 'upsell_2_b',
            name: 'upsell_2_b',
            parent_id: 'upsell_sub_course_tech',
            is_paid: false,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: true,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 513
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1500,
                    price: 1500,
                    start_price: 1500,
                    ltv: 1101
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                }
            ],
            config: {}
        },
        {
            id: 'upsell_2_b',
            name: 'upsell_2_b',
            parent_id: 'upsell_sub_course_tech',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: true,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 513
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1500,
                    price: 1500,
                    start_price: 1500,
                    ltv: 1101
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                }
            ],
            config: {}
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2_b',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 30,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 209
                }
            ],
            config: {}
        },
        {
            id: 'upsell_sub_course_tech',
            name: 'upsell_sub_course_tech',
            parent_id: 'offer_a4_foxtrot16',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'debe017b-c5a2-46ba-9c8f-8286b5a81852',
                    name: 'One-month intro (19.99) + One-month (19.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'wellcube_subs',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1,
                    price: 1999,
                    start_price: 3999,
                    ltv: 4256
                },
                {
                    payment_type: 'subscription',
                    id: 'f45bdefc-fb4a-4839-8208-fca7eb121c12',
                    name: 'Three-months intro (39.99) - Three-months (39.99)',
                    trial: 90,
                    period: 90,
                    product_code: 'wellcube_subs',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1,
                    price: 3999,
                    start_price: 3999,
                    ltv: 5872
                }
            ],
            config: {
                backgroundImg: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FC(w)/monetisation/wellcube.png',
                backgroundLabel: 'wellness_courses',
                courseLabel: 'streaming_platform',
                courseTitle: 'wellness_coach',
                courseDescription: 'professionals,lifelong_results,quality',
                disclaimerLogic: 'regular'
            }
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2_b',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 30,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 209
                }
            ],
            config: {}
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2_b',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 30,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 209
                }
            ],
            config: {}
        },
        {
            id: 'upsell_2_b',
            name: 'upsell_2_b',
            parent_id: 'upsell_sub_course_tech',
            is_paid: false,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: true,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 513
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 366
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_all',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1500,
                    price: 1500,
                    start_price: 1500,
                    ltv: 1101
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_nutrition',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'guide_cardio',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1000,
                    price: 1000,
                    start_price: 1000,
                    ltv: 734
                }
            ],
            config: {}
        },
        {
            id: 'upsell_sub_course_tech',
            name: 'upsell_sub_course_tech',
            parent_id: 'payment_g4_foxtrot16',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'debe017b-c5a2-46ba-9c8f-8286b5a81852',
                    name: 'One-month intro (19.99) + One-month (19.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'wellcube_subs',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1,
                    price: 1999,
                    start_price: 3999,
                    ltv: 4256
                },
                {
                    payment_type: 'subscription',
                    id: 'f45bdefc-fb4a-4839-8208-fca7eb121c12',
                    name: 'Three-months intro (39.99) - Three-months (39.99)',
                    trial: 90,
                    period: 90,
                    product_code: 'wellcube_subs',
                    is_preselected: false,
                    product_state: 'per_week',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 1,
                    price_per_state: 1,
                    price: 3999,
                    start_price: 3999,
                    ltv: 5872
                }
            ],
            config: {
                backgroundImg: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FC(w)/monetisation/wellcube.png',
                backgroundLabel: 'wellness_courses',
                courseLabel: 'streaming_platform',
                courseTitle: 'wellness_coach',
                courseDescription: 'professionals,lifelong_results,quality',
                disclaimerLogic: 'regular'
            }
        },
        {
            id: 'payment_g4_foxtrot16',
            name: 'payment_g4_foxtrot16_p3_sub_fullprice',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'c714c542-4373-41c9-b247-209815223772',
                    name: 'One-week intro (6.93) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 150,
                    price_per_state: 99,
                    price: 693,
                    start_price: 1049,
                    ltv: 2784
                },
                {
                    payment_type: 'subscription',
                    id: '9ea6bce5-34fe-4ee3-93d9-4ea5f455bc37',
                    name: 'One-month intro (15.19) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 100,
                    price_per_state: 49,
                    price: 1519,
                    start_price: 3099,
                    ltv: 3959
                },
                {
                    payment_type: 'subscription',
                    id: '7ad6132e-71fc-47c9-9e82-dcea25af4ac3',
                    name: 'Three-month intro (25.99) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 59,
                    price_per_state: 29,
                    price: 2599,
                    start_price: 5319,
                    ltv: 5618
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 150,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3409
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 100,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 5005
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'UA',
                    start_price_per_state: 59,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 6638
                }
            ],
            config: {
                productSubtitle: 'on',
                '2ndBadge': 'most_popular',
                '3dBadge': 'best_value',
                saveBadge: 'off',
                fullPrice: 'on',
                screenTitle: 'default'
            }
        }
    ],
    expire: 1633705410444,
};
