import { AnyAction } from 'redux';

import { getKgWeight } from 'helpers/utils';

import { IOnboardingStore } from 'types/store/onboardingStore';

import * as actionTypes from './actionTypes';

const initialState: IOnboardingStore = {
    config: null,
    gender: null,
    goal: null,
    current_body: null,
    want_body: null,
    target_zone: null,
    target_weight: null,
    training_level: null,
    how_fast: null,
    age: 0,
    tall: null,
    current_weight: null,
    psychLazy: null,
    psychBusy: null,
    measureSystem: 'IMPERIAL',
    onboardingScreensList: {},
    nextStep: '/',
    urlParams: null,
    name: '',
    diet: '',
    occasion: '',
    occasionDate: '',
    age_range: {},
    usersAmountData: null,
    goalId: undefined,
    dynamicConfig: null,
    extraWorkoutType: null,
    stepTracker: null,
    trainingDays: null,
    bodyParts: null,
    equipment: null,
    userWorkoutTypes: null,
    activity_level: null,
    scoringQuestion: {
        time: null,
        desk: null,
    },
};

const OnboardingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.SET_NEXT_STEP: {
            return { ...state, nextStep: action.payload };
        }
        case actionTypes.SET_GENDER:
            localStorage.setItem('gender', action.payload);

            return { ...state, gender: action.payload };
        case actionTypes.SET_CURRENT_BODY:
            return { ...state, current_body: action.payload };
        case actionTypes.SET_GOAL:
            return { ...state, goal: action.payload };
        case actionTypes.SET_TARGET_BODY:
            return { ...state, want_body: action.payload };
        case actionTypes.SET_TARGET_ZONE:
            return { ...state, target_zone: action.payload };
        case actionTypes.SET_CURRENT_WEIGHT: {
            const currentWeight = getKgWeight(action.payload);
            const measureSystemValue = action.payload.unit;

            return { ...state, current_weight: currentWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_CONFIG:
            return { ...state, config: action.payload };
        case actionTypes.SET_TARGET_WEIGHT: {
            const targetWeight = getKgWeight(action.payload);
            const measureSystemValue = action.payload.unit;

            return { ...state, target_weight: targetWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_TRAINING_LEVEL:
            return { ...state, training_level: action.payload };
        case actionTypes.SET_AGE:
            return { ...state, age: action.payload || 0 };
        case actionTypes.SET_AGE_RANGE:
            return { ...state, age_range: action.payload };
        case actionTypes.SET_TALL: {
            const { value: tall, unit: measureSystemValue } = action.payload;

            return { ...state, tall, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_PSYCHO_POWER:
            return { ...state, psychLazy: action.payload };
        case actionTypes.SET_PSYCHO_BUSY:
            return { ...state, psychBusy: action.payload };
        case actionTypes.SET_MEASURE_SYSTEM:
            return { ...state, measureSystem: action.payload };
        case actionTypes.SET_ONBOARDING_SCREENS_LIST:
            return { ...state, onboardingScreensList: action.payload };
        case actionTypes.SET_WITH_UPSALE:
            return { ...state, withUpsale: action.payload };
        case actionTypes.SET_NAME:
            return { ...state, name: action.payload };
        case actionTypes.SET_DIET:
            return { ...state, diet: action.payload };
        case actionTypes.SET_OCCASION:
            return { ...state, occasion: action.payload };
        case actionTypes.SET_OCCASION_DATE:
            return { ...state, occasionDate: action.payload };
        case actionTypes.SET_USERS_AMOUNT_DATA:
            return { ...state, usersAmountData: action.payload };

        case actionTypes.SET_EQUIPMENT:
            return { ...state, equipment: action.payload };

        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return { ...state, urlParams: action.payload };
        }

        case actionTypes.SET_TRAINING_DAYS: {
            return { ...state, trainingDays: action.payload };
        }

        case actionTypes.SET_BODY_PARTS: {
            return { ...state, bodyParts: action.payload };
        }

        case actionTypes.SET_GOAL_ID: {
            return { ...state, goalId: action.payload };
        }

        case actionTypes.SET_DYNAMIC_CONFIGS: {
            return { ...state, dynamicConfig: action.payload };
        }

        case actionTypes.SET_EXTRA_WORKOUT_TYPE: {
            return { ...state, extraWorkoutType: action.payload };
        }

        case actionTypes.SET_STEP_TRACKER: {
            return { ...state, stepTracker: action.payload };
        }

        case actionTypes.SET_USER_WORKOUT_TYPE: {
            return { ...state, userWorkoutTypes: action.payload };
        }

        case actionTypes.SET_ACTIVITY_LEVEL: {
            return { ...state, activity_level: action.payload };
        }

        case actionTypes.SET_SCORING_QUESTION_TIME: {
            return {
                ...state,
                scoringQuestion: {
                    ...state.scoringQuestion,
                    time: action.payload,
                },
            };
        }

        case actionTypes.SET_SCORING_QUESTION_DESK: {
            return {
                ...state,
                scoringQuestion: {
                    ...state.scoringQuestion,
                    desk: action.payload,
                },
            };
        }

        default:
            return state;
    }
};

export default OnboardingReducer;
